$('document').ready(function(){
	$('.serialslider').serialslider({
		directionNav: true
	});

	$('body').on('click', '.menu-burger', function(){
		$(this).parent('.menu-mobile').toggleClass('active');
		$('body').toggleClass('no-scroll');
	})
});
